import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-717c2aa1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_swiper_category_data = _resolveComponent("swiper-category-data")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  ($setup.parentPath)
                    ? (_openBlock(), _createBlock(_component_ion_buttons, {
                        key: 0,
                        slot: "start"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_back_button, {
                            class: "back-icon",
                            "default-href": $setup.parentPath
                          }, null, 8, ["default-href"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_searchbar, {
                    ref: "productSearchBar",
                    class: "product-searchbar",
                    mode: "ios",
                    modelValue: $setup.searchKeyword,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchKeyword) = $event)),
                    placeholder: $setup.t('ProductPage.search'),
                    onIonFocus: _cache[1] || (_cache[1] = ($event: any) => ($setup.isSearching = true)),
                    onIonBlur: _cache[2] || (_cache[2] = ($event: any) => ($setup.isSearching = false)),
                    onKeyup: _cache[3] || (_cache[3] = _withKeys((e) => e.target.blur(), ["enter"]))
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ion_spinner)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_swiper_category_data, {
                ref: "swiperProducts",
                userLoggedIn: $setup.userLoggedIn,
                allCategories: $setup.allProductCategories,
                allProducts: $setup.products
              }, null, 8, ["userLoggedIn", "allCategories", "allProducts"])
            ]),
            _: 1
          }, 512), [
            [_vShow, !$setup.isSearching || $setup.searchKeyword]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}