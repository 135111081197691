
// Vue reactivity
import { computed, onMounted, ref, watch } from "vue";

// icons
import { search, searchOutline, notificationsOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonContent, IonGrid, IonToolbar,
        IonSearchbar, IonButtons, IonButton, IonBackButton, IonSpinner } from "@ionic/vue";
import SwiperCategoryData from "@/components/templates/SwiperCategoryData.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { utils } from '@/composables/utils';

export default {
  name: "ProductListPage",
  components: {
    IonPage, IonHeader, IonContent, IonGrid, IonToolbar,
    IonSearchbar, IonButtons, IonButton, IonBackButton, IonSpinner,
    SwiperCategoryData,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const preSelectCategoryId: any = computed(() => route.params.category); // route paramters (mainly ID)
    const parentPath: any = computed(() => route.params.parentPath); // route paramters (mainly ID)
    const from: any = computed(() => route.params.from); // check if redirected from search icon in home page

    // 1. declare state variables (ref to make them reactive)
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingProducts);
    const allProducts = computed(() => store.state.allProducts);
    const allProductCategories = computed(() => store.state.allProductCategories);
    const products = ref<any>(allProducts.value); // filtered products
    const searchKeyword = ref("");
    const productSearchBar = ref(null);
    const isSearching = ref(false);

    const swiperProducts = ref(null);
    const updateSwiperCategory = (newId: any) => {
      if (swiperProducts.value) {
        swiperProducts.value.onCategoryChanged(newId, allProductCategories.value);
        swiperProducts.value.syncCategoryFilter();
      }
    }

    const { t } = useI18n();
    const { getLocalizedStr, } = utils();

    // 2. watch changes of state variables
    watch(preSelectCategoryId, (currCategoryId: any) => {
      if (currCategoryId) updateSwiperCategory(currCategoryId);
    })
    watch(from, (currFrom: any) => {
      if (currFrom == 'searchIcon') { // redirect from home page search button
        setTimeout(() => { productSearchBar.value.$el.setFocus() }, 150);
      }
    })
    const checkMatchKeyword = (obj: any, keyword: any) => {
      return `${obj.title.toLowerCase()} ${obj.merchantName.toLowerCase()}`.includes(keyword);
    }
    watch(allProducts, (currProducts, prevProducts) => { // triggered only when direct access to products page
      products.value = currProducts.filter((obj: any) => checkMatchKeyword(obj, searchKeyword.value));
    })
    watch(searchKeyword, (currKeyword, prevKeyword) => {
      const keyword = currKeyword.toLowerCase();
      products.value = allProducts.value.filter((obj: any) => checkMatchKeyword(obj, keyword));
    })

    onMounted(() => {
      if (from.value == 'searchIcon') { // redirect from home page search button
        setTimeout(() => { productSearchBar.value.$el.setFocus() }, 150);
      }
      if (preSelectCategoryId.value) { // first enter
        updateSwiperCategory(preSelectCategoryId.value);
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      search, searchOutline, notificationsOutline,
      
      // state variables
      loading, parentPath, userLoggedIn,
      products, allProductCategories,
      productSearchBar, searchKeyword, isSearching,
      swiperProducts,

      // methods
      t, getLocalizedStr,
    };
  },
};
